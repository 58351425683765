import React from 'react'
import { Box } from '@walltowall/calico'
import { Helmet } from 'react-helmet-async'
import { SkipNavLink } from '@reach/skip-nav'

import { useSiteSettings } from '../hooks/useSiteSettings'

import { DevPanel } from './DevPanel'
import { Text } from './Text'
import { SecurityBeacon } from './SecurityBeacon'

export type LayoutProps = {
  children?: React.ReactNode
}
export const Layout = ({ children }: LayoutProps) => {
  const settings = useSiteSettings()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{settings.siteName}</title>
        <meta name="description" content={settings.siteDescription} />
        <link
          rel="preload"
          href="https://cloud.typography.com/6033652/7716392/css/fonts.css"
          as="style"
        />
        <link
          rel="stylesheet"
          href="https://cloud.typography.com/6033652/7716392/css/fonts.css"
        />
        <link
          rel="prefetch"
          as="image"
          type="image/svg+xml"
          href="/images/sprites.svg"
        />
      </Helmet>
      <SkipNavLink>
        <Text>Skip to content</Text>
      </SkipNavLink>
      <Box
        component="main"
        styles={{
          backgroundColor: 'beige90',
          color: 'brown20',
          fontFamily: 'sans',
        }}
      >
        {children}
      </Box>
      {/* Client provided "ZeroFox" tracking pixel. Only added to the production version of the site. */}
      {process.env.NODE_ENV === 'production' && <SecurityBeacon />}
      {/* {process.env.NODE_ENV === 'development' && <DevPanel />} */}
    </>
  )
}
